import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import axios from "axios";
import { Bar, Doughnut } from "react-chartjs-2";
import { styled } from '@mui/material/styles';
import Autocomplete from "@mui/material/Autocomplete";
import CheckIcon from "@mui/icons-material/Check";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, CardHeader, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Tooltip, IconButton, Popover, List, ListItem, ListItemText, Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import GroupIcon from "@material-ui/icons/Group";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MDBox from "components/MDBox";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import useCountsDetailsData from "../../hooks/useCountsDetailsData";
import { COUNT_TYPES } from "../../constants/countsEmployeeTypes";
import CountDetailDialog from "../../components/Dialogs/CountDetailDialog";

const AttendanceLabel = ({ presentCount, absentCount, onAbsentClick }) => (
  <div className="flex items-center space-x-2">
    <span
      style={{
        fontWeight: "bold",
        color: "#2e7d32",
      }}
    >
      Present: {presentCount}
    </span>
    <span
      style={{
        fontWeight: "bold",
        color: "#ef6c00",
        marginLeft: "10px",
        cursor: "pointer",
        textDecoration: "underline"
      }}
      onClick={absentCount > 0 ? onAbsentClick : () => { }}
    >
      Absent: {absentCount}
    </span>
  </div>
);
const MemoizedProjectStatusChart = memo(
  ({ pieChartData1 }) =>
    pieChartData1.labels.length > 0 && (


      <Doughnut
        data={pieChartData1}
        options={{
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: (context) => {
                  const label = context.label || "";
                  const value = context.formattedValue || "";
                  const index = context.dataIndex;
                  const count = pieChartData1.datasets[0].data[index];
                  return `${label}: ${value}`;
                },
              },
            },
            legend: {
              position: "right",
              labels: {
                generateLabels: function (chart) {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map((label, i) => {
                      const dataset = data.datasets[0];
                      const count = dataset.data[i];
                      return {
                        text: `${label} (${count})`,
                        fillStyle: dataset.backgroundColor[i],
                        hidden:
                          isNaN(dataset.data[i]) || dataset.data[i] === 0,
                      };
                    });
                  }
                  return [];
                },
              },
            },
          },
          cutout: "60%",
        }}
      />

    )
);

const TaskWiseBarChart = () => {
  const [absentCount, setAbsentCount] = useState(0);

  const {
    countDetailsDialogOpen,
    setCountDetailsDialogOpen,
    countDetailsDialogTitle,
    countDetailsEmployeeList,
    countDetailsEmployeeListColumns,
    countDetailsIsLoading,
    fetchCountDetailsEmployeeList
  } = useCountsDetailsData();

  const [isDialogLoading, setIsDialogLoading] = useState(false);

  const handleFetchCountsDetails = async (type) => {
    setIsDialogLoading(true);
    try {
      await fetchCountDetailsEmployeeList(type, {
        selectedDate: selectedDate.toISOString().split('T')[0],
        team: selectedTeam,
        projectName: selectedProject
      });
    } finally {
      setIsDialogLoading(false);
    }
  };

  const ClickableCount = styled('div')({
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#7171ff',
  });

  const BillableTasksTable = () => (
    <Card>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Billable Tasks</Typography>
            <Typography variant="subtitle2" color="#2e7d32" fontWeight="bold">
              Total Billable Employees: {taskData.billableEmployeeCount}
            </Typography>
          </Box>
        }
      />
      <CardContent>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={taskData.billableTasks}
            columns={billableTaskColumns}
            pageSize={5}
            getRowId={(row) => `${row.projectName}-${row.team}`}
          />
        </div>
      </CardContent>
    </Card>
  );
  const NonBillableTasksTable = () => (
    <Card>
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" color="text.primary">Non-Billable Tasks</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" color="#ff6600" fontWeight="bold" mr={2}>
                Total Non-Billable: {taskData.nonBillableEmployeeCount}
              </Typography>
              <Typography variant="subtitle2" color="#1976d2" fontWeight="bold" mr={2}>
                Training: {taskData.trainingCount}
              </Typography>
              <Typography variant="subtitle2" color="#7b1fa2" fontWeight="bold">
                Idle: {taskData.idleCount}
              </Typography>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={taskData.nonBillableTasks}
            columns={nonBillableTaskColumns}
            pageSize={5}
            getRowId={(row) => `${row.projectName}-${row.team}`}
          />
        </div>
      </CardContent>
    </Card>
  );

  const apiUrl = process.env.REACT_APP_API_URL;

  const getPreviousDayDate = () => {
    const currentDate = new Date();
    // Set the date to the previous day
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    return previousDate;
  };

  // Set both start and end date to the previous day's date
  const previousDate = getPreviousDayDate();
  const [selectedDate, setSelectedDate] = useState(previousDate);
  const [allProjectNames, setAllProjectNames] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [batchValue, setBatchValue] = useState(null);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [batchCountByTeam, setBatchCountByTeam] = useState(null);
  const [taskData, setTaskData] = useState({
    billableTasks: [],
    nonBillableTasks: [],
    billableEmployeeCount: 0,
    nonBillableEmployeeCount: 0,
    trainingCount: 0,
    idleCount: 0
  });


  const [teamProjects, setTeamProjects] = useState([]);




  const [teams, setTeams] = useState([]);
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${apiUrl}/teams`);
        setTeams(response.data);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/task/get-detail`, {
          params: {
            selectedDate: selectedDate.toISOString().split('T')[0],
            team: selectedTeam,
            projectName: selectedProject
          }
        });
        setTaskData(response.data);
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    };
    fetchTaskData();

    fetchDataBasedOnProject(selectedProject, selectedTeam);
  }, [selectedDate, selectedTeam, selectedProject]);

  useEffect(() => {
    const getAbsentCount = async () => {
      const response = await axios.get(`${apiUrl}/api/attendance/get-absence-count`, {
        params: {
          selectedDate: selectedDate.toISOString().split('T')[0],
        }
      });

      if (response.data.success === true) {
        setAbsentCount(response.data.count);
      }

      if (response.status !== 200) {
        console.log("Error fetching absent count:", response.data);
        setAbsentCount(null);
      }
    };

    getAbsentCount();
  }, [selectedDate]);

  const TasksCell = ({ value }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'tasks-popover' : undefined;

    return (
      <div>
        <Tooltip title={`${value.length} tasks`}>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
        <span>{value.length} tasks</span>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Light black background
            },
          }}
        >
          <List>
            {value.map((task, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={task}
                  primaryTypographyProps={{ style: { color: 'white' } }} // White text for better contrast
                />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    );
  };

  const billableTaskColumns = [
    { field: 'projectName', headerName: 'Project Name', flex: 1 },
    { field: 'team', headerName: 'Team', flex: 1 },
    { field: 'employeeCount', headerName: 'Employee Count', flex: 1 },
    { field: 'totalTaskCount', headerName: 'Task Count', flex: 1 },
    {
      field: 'tasks',
      headerName: 'Tasks',
      flex: 2,
      renderCell: (params) => <TasksCell value={params.value} />,
    },
  ];

  const nonBillableTaskColumns = [
    { field: 'projectName', headerName: 'Project Name', flex: 1 },
    { field: 'team', headerName: 'Team', flex: 1 },
    { field: 'employeeCount', headerName: 'Employee Count', flex: 1 },
    { field: 'totalTaskCount', headerName: 'Task Count', flex: 1 },
    {
      field: 'tasks',
      headerName: 'Tasks',
      flex: 2,
      renderCell: (params) => <TasksCell value={params.value} />,
    },
  ];

  const fetchDataBasedOnProject = async (newProject, newTeam) => {
    try {
      if (newProject == null && newTeam == null) {
        const response = await axios.get(`${apiUrl}/api/employee/get-count`, {
          params: {
            selectedDate: selectedDate.toISOString().split('T')[0],
          }
        });

        setEmployeeCount(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProjectChange = async (event, newProject) => {
    setSelectedProject(newProject);
    await fetchDataBasedOnProject(newProject, selectedTeam);
  };

  const handleFetchProjectsForTeam = async (team) => {
    try {
      const response = await axios.get(`${apiUrl}/projectNames?team=${team}`);

      // Filter out projects that contain "Dumbledore" or "Dumbledore-1", but include "Dumbledore Karur"
      const filteredProjects = response.data.filter(project =>
        !project.includes("Dumbledore") &&
        !project.includes("Dumbledore-1") ||
        project === "Dumbledore Karur" // Explicitly include "Dumbledore Karur"
      );

      // Set the filtered projects to state
      setTeamProjects(filteredProjects);
    } catch (error) {
      console.error(`Error fetching projects for ${team} team:`, error);
    }
  };

  const handleTeamChange = async (event, newTeam) => {
    setSelectedTeam(newTeam);
    await fetchDataBasedOnProject(selectedProject, newTeam);
    setSelectedProject(null);
    try {
      handleFetchProjectsForTeam(newTeam);
    } catch (error) {
      console.error("Error fetching projects for the team:", error);
    }
  };

  useEffect(() => {
    // Fetch all project names when the component mounts
    const fetchAllProjectNames = async () => {
      try {
        const response = await axios.get(`${apiUrl}/projectNames`); // Replace with the actual endpoint to get all project names
        setAllProjectNames(response.data);
      } catch (error) {
        console.error("Error fetching all project names:", error);
      }
    };

    fetchAllProjectNames();
    fetchDataBasedOnProject(null, null);
  }, []);

  const labelColors = {
    "POC": "#b75e4c", // Blue
    "NOT-Started": "#fe9f1b", // Yellow
    "Training": "#9F00FF", // Purple
    "In-Progress": "#2196F3", // Blue
    "Completed-Won": "#8BC34A", // Light Green
    "Completed-Lost": "#FF5722", // Deep Orange
  };

  const [pieChartData1, setPieChartData1] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  });
  useEffect(() => {
    const fetchProjectStatusData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/projectStatus`);
        const projectStatusData = response.data;

        // Assuming you have a function like aggregateStatus1Data to aggregate data
        const aggregatedData = aggregateStatus1Data(projectStatusData);

        const totalCount = aggregatedData.reduce(
          (sum, item) => sum + item.count,
          0
        );

        const percentages = aggregatedData.map((item) => item.count);

        setPieChartData1((prevData) => ({
          ...prevData,
          labels: aggregatedData.map((item) => item.status1),
          datasets: [
            {
              data: percentages,
              backgroundColor: aggregatedData.map(
                (item) => labelColors[item.status1] || "#000"
              ),
              hoverBackgroundColor: aggregatedData.map(
                (item) => labelColors[item.status1] || "#000"
              ),
            },
          ],
        }));
      } catch (error) {
        console.error("Error fetching project status data:", error);
      }
    };

    fetchProjectStatusData();
  }, []);

  // Function to aggregate counts for the same status1 values
  const aggregateStatus1Data = (data) => {
    const status1Map = new Map();

    data.forEach((item) => {
      const { status1, count } = item;
      if (status1Map.has(status1)) {
        status1Map.set(status1, status1Map.get(status1) + count);
      } else {
        status1Map.set(status1, count);
      }
    });

    return Array.from(status1Map.entries()).map(([status1, count]) => ({
      status1,
      count,
    }));
  };

  const [status1CountByProject, setStatus1CountByProject] = useState([]);

  useEffect(() => {
    const fetchStatus1CountByProject = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/status1CountByProject`);
        setStatus1CountByProject(response.data);
      } catch (error) {
        console.error("Error fetching status1 count by project:", error);
      }
    };

    fetchStatus1CountByProject();
  }, []);

  const [comparisonData, setComparisonData] = useState({
    totalEmployees: 0,
    presentEmployees: 0,
    absentEmployees: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/compareData`);
        setComparisonData(response.data);
      } catch (error) {
        console.error("Error fetching comparison data:", error);
      }
    };

    fetchData();
  }, []);



  const [data, setData] = useState([]);
  const [dataTwo, setInitialData] = useState([]);
  useEffect(() => {
    axios.get(`${apiUrl}/admin`).then((response) => {
      // Update initial data
      setInitialData(response.data);
      setData(response.data);
    });
  }, []);
  const formattedData = useMemo(() => {
    const reversedData = data.map((row) => ({
      ...row,
      id: row._id,
    }));
    reversedData.reverse();
    return reversedData;
  }, [data]);

  const statusIcons = {
    POC: <SelfImprovementIcon />,
    "NOT-Started": <SelfImprovementIcon />,
    "Training:": <SelfImprovementIcon />,
    "In-Progress": <DirectionsRunIcon />,
    "Completed-Won": <CheckIcon />,
    "Completed-Lost": <CloseIcon />,
  };

  const columnsTwo = [
    { field: "projectname", headerName: "Projectname", flex: 1 },
    { field: "team", headerName: "Department", flex: 1 },
    {
      field: "jobs.managerTeam",
      headerName: "Manager",
      flex: 1,
      renderCell: (params) => (
        <div style={{ padding: "8px" }}>{params.row.jobs?.managerTeam}</div>
      ),
    },
    {
      field: "jobs.status1",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            padding: "2px",
            borderBottom: `5px solid`,
            borderRadius: `5px `,
            color: labelColors[params.row.jobs?.status1],
          }}
        >
          {statusIcons[params.row.jobs?.status1]}
          {params.row.jobs?.status1}
        </div>
      ),
    },
  ];

  // Sum of production and idle counts
  const sumOfCounts = taskData.billableEmployeeCount + taskData.nonBillableEmployeeCount;

  const calculateAttendanceData = () => {
    let presentCount = 0;

    if (selectedProject) {
      presentCount = sumOfCounts;
    } else if (selectedTeam) {
      presentCount = sumOfCounts;
    } else {
      presentCount = sumOfCounts;
    }

    return {
      presentCount,
    };
  };

  // Get the attendance data
  const attendanceData = calculateAttendanceData();




  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Row 1: Filters */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Start Date"
            type="date"
            value={selectedDate.toISOString().split("T")[0]}
            onChange={(event) => setSelectedDate(new Date(event.target.value))}
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            value={selectedTeam}
            onChange={handleTeamChange}
            options={teams}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Team"
                fullWidth
                variant="outlined"
                color="secondary"
              />
            )}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": { padding: 0.5 }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            value={selectedProject}
            onChange={handleProjectChange}
            options={selectedTeam ? teamProjects : allProjectNames}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project Name"
                fullWidth
                variant="outlined"
                color="secondary"
              />
            )}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": { padding: 0.6 }
            }}
          />
        </Grid>
      </Grid>

      {/* Row 2: Cards (Total Employees, Billable, Non-Billable, Attendance, Projects) */}
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="primary"
              icon={<GroupIcon />}
              title="Total Employees"
              count={
                selectedProject || selectedTeam || employeeCount == null ?
                  "-" : <ClickableCount onClick={employeeCount > 0 ? () => handleFetchCountsDetails(COUNT_TYPES.TOTAL) : () => { }}>{employeeCount}</ClickableCount>
              }
            // percentage={{
            //   color: getStatusColor(),
            //   amount: getStatusMessage(),
            //   label: `Last updated: ${formatDate(batchDate)}`,
            // }}

            />
          </MDBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              icon={<MoreTimeIcon />}
              title="Billable Employees"
              count={<ClickableCount onClick={taskData.billableEmployeeCount > 0 ? () => handleFetchCountsDetails(COUNT_TYPES.BILLABLE) : () => { }}>{taskData.billableEmployeeCount}</ClickableCount>}
              percentage={{
                color: "success",
                amount: "",
                label: `${taskData.billableTasks.reduce((sum, task) => sum + task.totalTaskCount, 0)} Tasks`,
              }}
            />
          </MDBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="success"
              icon={<WorkOutlineIcon />}
              title="Non-Billable Employees"
              count={<ClickableCount onClick={taskData.nonBillableEmployeeCount > 0 ? () => handleFetchCountsDetails(COUNT_TYPES.NON_BILLABLE) : () => { }}>{taskData.nonBillableEmployeeCount}</ClickableCount>}
              percentage={{
                color: "success",
                amount: "",
                label: `${taskData.nonBillableTasks.reduce((sum, task) => sum + task.totalTaskCount, 0)} Tasks`,
              }}
            />
          </MDBox>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="info"
              icon={<EventAvailableIcon />}
              title="Attendance Status"
              count={attendanceData.presentCount}
              percentage={{
                color: "success",
                amount: "",
                label: <AttendanceLabel
                  presentCount={attendanceData.presentCount}
                  absentCount={(selectedProject || selectedTeam || absentCount == null) ? "-" : absentCount}
                  onAbsentClick={() => handleFetchCountsDetails(COUNT_TYPES.ABSENT)}
                />,
              }}
            />
          </MDBox>
        </Grid>

        {/* <Grid item xs={12} sm={6} md={2.4}>
          <MDBox mb={1.5}>
            <ComplexStatisticsCard
              color="secondary"
              icon={<WorkIcon />}
              title="Projects"
              count={allProjectNames.length}
              percentage={{
                color: "success",
                amount: "",
                label: "Over all Projects",
              }}
            />
          </MDBox>
        </Grid> */}
      </Grid>


      {/* Row 3: Tables (Billable & Non-Billable Tasks) */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={12}>
          <BillableTasksTable />
        </Grid>

      </Grid>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={12}>
          <NonBillableTasksTable />
        </Grid>

      </Grid>


      {/* Row 4: Charts (Project Status and Task Report) */}
      <Grid container spacing={2} mt={2}>
        {/* Table section */}
        <Grid item xs={12} md={9}>
          <Card sx={{ height: "100%" }}>
            <CardHeader
              title={<h3 style={{ fontSize: "17px" }}>Latest project report</h3>}
            />
            <CardContent>
              <div
                style={{
                  height: 330, // Set a fixed height
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <DataGrid
                  rows={formattedData}
                  getRowId={(row) => row._id}
                  columns={columnsTwo}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* Chart section */}
        <Grid item xs={12} md={3}>
          <Card sx={{ height: "100%" }}>
            <CardHeader
              title={<h3 style={{ fontSize: "17px" }}>Project Status</h3>}
            />
            <CardContent sx={{ height: 330 }}>
              <MemoizedProjectStatusChart pieChartData1={pieChartData1} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {isDialogLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <CountDetailDialog
        open={countDetailsDialogOpen}
        onClose={() => setCountDetailsDialogOpen(false)}
        title={countDetailsDialogTitle}
        employees={countDetailsEmployeeList}
        employeeListColumns={countDetailsEmployeeListColumns}
        loading={countDetailsIsLoading}
      />

    </DashboardLayout>

  );
};

export default TaskWiseBarChart;
