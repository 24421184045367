import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const CountDetailDialog = ({ open, onClose, title, employees, employeeListColumns, loading }) => {
    const formattedColumns = employeeListColumns.map((col) => ({
        field: col,
        headerName: col.toUpperCase(),
        width: 230,
    }));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={employees}
                        columns={formattedColumns}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        initialState={{
                            pagination: {
                                pageSize: 25,
                            },
                        }}
                        getRowId={(row) => `${row.emp_id} - ${row.employeeNo}`}
                        disableSelectionOnClick
                        loading={loading}
                        components={{
                            Toolbar: () => (
                                <div>
                                    <GridToolbar />
                                </div>
                            ),
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CountDetailDialog;