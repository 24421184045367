import { useState } from 'react';
import { COUNT_TYPES } from '../constants';
import { COUNTS_DETAILS_API_ENDPOINTS } from '../constants/apiEndpoints';
import axios from 'axios';

const useCountsDetailsData = () => {
    const [countDetailsDialogOpen, setCountDetailsDialogOpen] = useState(false);
    const [countDetailsDialogTitle, setCountDetailsDialogTitle] = useState('');
    const [countDetailsEmployeeList, setCountDetailsEmployeeList] = useState([]);
    const [countDetailsEmployeeListColumns, setCountDetailsEmployeeListColumns] = useState([]);
    const [countDetailsIsLoading, setCountDetailsIsLoading] = useState(false);

    const baseAPIURL = process.env.REACT_APP_API_URL;

    const fetchCountDetailsEmployeeList = async (type, params) => {
        setCountDetailsIsLoading(true);

        try {
            let apiUrl = '';
            let response = null;

            switch (type) {
                case COUNT_TYPES.TOTAL:
                    apiUrl = `${baseAPIURL}${COUNTS_DETAILS_API_ENDPOINTS.TOTAL}`;

                    response = await axios.get(apiUrl, {
                        params: {
                            ...params
                        }
                    });

                    setCountDetailsEmployeeList(response.data.employees);
                    setCountDetailsEmployeeListColumns(response.data.employeeListColumns);
                    setCountDetailsDialogTitle(`${type.charAt(0).toUpperCase() + type.slice(1)} Employees`);
                    setCountDetailsDialogOpen(true);

                    break;
                case COUNT_TYPES.BILLABLE:
                    apiUrl = `${baseAPIURL}${COUNTS_DETAILS_API_ENDPOINTS.PRESENT}`;

                    response = await axios.get(apiUrl, {
                        params: {
                            ...params
                        }
                    });

                    setCountDetailsEmployeeList(response.data.billableEmployees);
                    setCountDetailsEmployeeListColumns(response.data.employeeListColumns);
                    setCountDetailsDialogTitle(`${type.charAt(0).toUpperCase() + type.slice(1)} Employees`);
                    setCountDetailsDialogOpen(true);

                    break;
                case COUNT_TYPES.NON_BILLABLE:
                    apiUrl = `${baseAPIURL}${COUNTS_DETAILS_API_ENDPOINTS.PRESENT}`;
                    
                    response = await axios.get(apiUrl, {
                        params: {
                            ...params
                        }
                    });

                    setCountDetailsEmployeeList(response.data.nonBillableEmployees);
                    setCountDetailsEmployeeListColumns(response.data.employeeListColumns);
                    setCountDetailsDialogTitle(`${type.charAt(0).toUpperCase() + type.slice(1)} Employees`);
                    setCountDetailsDialogOpen(true);

                    break;
                case COUNT_TYPES.BILLABLE_ADMIN:
                    apiUrl = `${baseAPIURL}${COUNTS_DETAILS_API_ENDPOINTS.PRESENT_ADMIN}`;
                    
                    response = await axios.get(apiUrl, {
                        params: {
                            ...params
                        }
                    });

                    setCountDetailsEmployeeList(response.data.billableEmployees);
                    setCountDetailsEmployeeListColumns(response.data.employeeListColumns);
                    setCountDetailsDialogTitle(`${type.charAt(0).toUpperCase() + type.slice(1, -5)} Employees`);
                    setCountDetailsDialogOpen(true);

                    break;
                case COUNT_TYPES.NON_BILLABLE_ADMIN:
                    apiUrl = `${baseAPIURL}${COUNTS_DETAILS_API_ENDPOINTS.PRESENT_ADMIN}`;
                    
                    response = await axios.get(apiUrl, {
                        params: {
                            ...params
                        }
                    });

                    setCountDetailsEmployeeList(response.data.nonBillableEmployees);
                    setCountDetailsEmployeeListColumns(response.data.employeeListColumns);
                    setCountDetailsDialogTitle(`${type.charAt(0).toUpperCase() + type.slice(1, -5)} Employees`);
                    setCountDetailsDialogOpen(true);

                    break;
                case COUNT_TYPES.ABSENT:
                    apiUrl = `${baseAPIURL}${COUNTS_DETAILS_API_ENDPOINTS.ABSENT}`;
                    
                    response = await axios.get(apiUrl, {
                        params: {
                            ...params
                        }
                    });

                    setCountDetailsEmployeeList(response.data.absentees);
                    setCountDetailsEmployeeListColumns(response.data.employeeListColumns);
                    setCountDetailsDialogTitle(`${type.charAt(0).toUpperCase() + type.slice(1)} Employees`);
                    setCountDetailsDialogOpen(true);

                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error("Error fetching employee details:", error);
        } finally {
            setCountDetailsIsLoading(false);
        }
    };

    return {
        countDetailsDialogOpen,
        setCountDetailsDialogOpen,
        countDetailsDialogTitle,
        countDetailsEmployeeList,
        countDetailsEmployeeListColumns,
        countDetailsIsLoading,
        fetchCountDetailsEmployeeList
    };
};

export default useCountsDetailsData;